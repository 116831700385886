const getChannelMessageResult = async (actionName) => {
    // we can't get a client that sent the current request, therefore we need 
    // to ask any controlled page for auth token
    const allClients = await self.clients.matchAll();
    const client = allClients.filter(client => client.type === 'window')[0];

    // if there is no page in scope, we can't get any token
    // and we indicate it with null value
    if (!client) {
        return null;
    }

    // to communicate with a page we will use MessageChannels 
    // they expose pipe-like interface, where a receiver of 
    // a message uses one end of a port for messaging and 
    // we use the other end for listening
    const channel = new MessageChannel();

    client.postMessage({
        'action': actionName
    }, [channel.port1]);

    // ports support only onmessage callback which 
    // is cumbersome to use, so we wrap it with Promise
    return new Promise((resolve, reject) => {
        channel.port2.onmessage = event => {
            if (event.data.error) {
                console.error('Port error', event.error);
                reject(event.data.error);
            }

            resolve(event.data);
        }
    });
}

const getFileResponse = async (request, replaceBaseAddressWithAthenaDomain) => {
    const headers = { ...request.headers };
    const { authHeader: token } = await getChannelMessageResult('getAthenaConfig');
    let requestUrl = request.url;
    if (replaceBaseAddressWithAthenaDomain) {
        const { baseUrl: athenaBaseAddress } = await getChannelMessageResult('getAthenaBaseAddress');
        const parsed = new URL(request.url);
        requestUrl = athenaBaseAddress + parsed.pathname;
    }
    if (token === null) {
        return new Response(null, {
            status: 401,
            statusText: 'Unauthorized'
        });
    }
    headers['Authorization'] = token;
    return fetch(new Request(requestUrl, {
        method: request.method,
        headers,
        mode: 'cors',
        credentials: 'same-origin',
    }));
}

export const fetchHandler = (event) => {
    const request = event.request;
    const parsed = new URL(request.url);
    if (event.request.destination == 'image' && (parsed.pathname.startsWith('/DocumentImage/') || parsed.pathname.includes('imgForRtx'))) {
        event.respondWith(
            getFileResponse(event.request, parsed.pathname.includes('imgForRtx'))
        );
    } else {
        event.respondWith(
            fetch(event.request)
        );
    }
} 