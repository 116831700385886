import { fetchHandler } from './fetchHandler'

self.addEventListener('fetch', fetchHandler);

self.addEventListener('activate', () => {
    return self.clients.claim();
});

self.addEventListener('install', () => {
    self.skipWaiting();
});

